
import AuthCheckIcon from '../../AuthLayout/AuthCheckIcon.vue'
import { defineComponent, computed, watchEffect } from 'vue'
import { MINIMUM_PASSWORD_LENGTH } from '@/constants'
import {
  CAPITAL_LETTERS_REGEX,
  SPECIAL_SYMBOL_REGEX,
  NUMBER_REGEX,
} from '@/helpers/regex'

export default defineComponent({
  components: { AuthCheckIcon },
  props: {
    password: {
      type: String,
      required: true,
      default: '',
    },
    passwordAgain: {
      type: String,
      required: true,
      default: '',
    },
    isValid: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['update:isValid'],
  setup(props, { emit }) {
    const enoughChars = computed(
      () => props.password.length >= MINIMUM_PASSWORD_LENGTH
    )
    const enoughCapitalLetters = computed(() =>
      Boolean((props.password.match(CAPITAL_LETTERS_REGEX) || []).length)
    )
    const enoughSpecialSymbols = computed(() =>
      Boolean((props.password.match(SPECIAL_SYMBOL_REGEX) || []).length)
    )
    const enoughNumbers = computed(() =>
      Boolean((props.password.match(NUMBER_REGEX) || []).length)
    )
    const passwordsMatch = computed(() =>
      Boolean(props.password && props.password === props.passwordAgain)
    )

    watchEffect(() => {
      if (
        enoughChars.value &&
        enoughCapitalLetters.value &&
        enoughSpecialSymbols.value &&
        passwordsMatch.value
      )
        emit('update:isValid', true)
      else emit('update:isValid', false)
    })
    return {
      enoughChars,
      enoughCapitalLetters,
      enoughSpecialSymbols,
      enoughNumbers,
      passwordsMatch,
    }
  },
})
