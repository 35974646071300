import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2.5 text-base max-w-[230px]" }
const _hoisted_2 = { class: "flex items-center mt-1 text-base" }
const _hoisted_3 = { class: "flex items-center mt-1 text-base" }
const _hoisted_4 = { class: "flex items-center mt-1 text-base" }
const _hoisted_5 = { class: "flex items-center mt-1 text-base" }
const _hoisted_6 = { class: "flex items-center mt-1 text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthCheckIcon = _resolveComponent("AuthCheckIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('PASSWORD_REQUIREMENTS_TITLE')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AuthCheckIcon, { active: _ctx.enoughChars }, null, 8, ["active"]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('PASSWORD_REQUIREMENTS_ENOUGH_LETTERS')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AuthCheckIcon, { active: _ctx.enoughCapitalLetters }, null, 8, ["active"]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('PASSWORD_REQUIREMENTS_ENOUGH_CAPITAL_LETTER')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AuthCheckIcon, { active: _ctx.enoughNumbers }, null, 8, ["active"]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('PASSWORD_REQUIREMENTS_ENOUGH_NUMBER')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_AuthCheckIcon, { active: _ctx.enoughSpecialSymbols }, null, 8, ["active"]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('PASSWORD_REQUIREMENTS_ENOUGH_SPECIAL_SYMBOL')), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_AuthCheckIcon, { active: _ctx.passwordsMatch }, null, 8, ["active"]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('PASSWORD_REQUIREMENTS_PASSWORDS_MATCH')), 1)
    ])
  ]))
}