import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-8 pb-4 pl-24 pr-20 bg-white rounded-lg max-w-814 w-full" }
const _hoisted_2 = { class: "mb-4 text-base-title font-medium" }
const _hoisted_3 = { class: "mb-5 flex justify-between" }
const _hoisted_4 = { class: "max-w-294 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiInputText = _resolveComponent("UiInputText")!
  const _component_PasswordRequirements = _resolveComponent("PasswordRequirements")!
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", null, null, 512), [
      [_vShow, _ctx.isError]
    ]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('SETTINGS_MY_PASSWORD_TITLE')), 1),
    _createElementVNode("form", {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_UiInputText, {
            modelValue: _ctx.form.oldPassword.value.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.oldPassword.value.value) = $event)),
            errorMessage: _ctx.form.oldPassword.errorMessage.value,
            label: _ctx.form.oldPassword.label,
            type: _ctx.passwordType,
            showClear: true,
            class: "mb-4",
            onShowPassword: _ctx.handleVisibility
          }, null, 8, ["modelValue", "errorMessage", "label", "type", "onShowPassword"]),
          _createVNode(_component_UiInputText, {
            modelValue: _ctx.form.newPassword.value.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.newPassword.value.value) = $event)),
            errorMessage: _ctx.form.newPassword.errorMessage.value,
            label: _ctx.form.newPassword.label,
            type: _ctx.passwordType,
            class: "mb-4",
            onShowPassword: _ctx.handleVisibility
          }, null, 8, ["modelValue", "errorMessage", "label", "type", "onShowPassword"]),
          _createVNode(_component_UiInputText, {
            modelValue: _ctx.form.newPasswordAgain.value.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.newPasswordAgain.value.value) = $event)),
            errorMessage: _ctx.form.newPasswordAgain.errorMessage.value,
            label: _ctx.form.newPasswordAgain.label,
            type: _ctx.passwordType,
            onShowPassword: _ctx.handleVisibility
          }, null, 8, ["modelValue", "errorMessage", "label", "type", "onShowPassword"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_PasswordRequirements, {
            isValid: _ctx.isPasswordValid,
            "onUpdate:isValid": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isPasswordValid) = $event)),
            password: _ctx.form.newPassword.value.value,
            "password-again": _ctx.form.newPasswordAgain.value.value
          }, null, 8, ["isValid", "password", "password-again"])
        ])
      ]),
      _createVNode(_component_UiButton, {
        class: "bg-green text-white text-base py-4 px-12 hover:bg-lightGreen",
        type: "submit",
        loading: _ctx.isSubmitting,
        disabled: !_ctx.isValid || !_ctx.meta.dirty || !_ctx.isPasswordValid
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('SETTINGS_CONFIRMATION_BUTTON')), 1)
        ]),
        _: 1
      }, 8, ["loading", "disabled"])
    ], 32)
  ]))
}