
import { defineComponent, ref, reactive, computed } from 'vue'
import {
  useField,
  useForm,
  useIsFormValid,
  useIsSubmitting,
} from 'vee-validate'
import { useI18n } from 'vue-i18n'
import useStore from '@/store'
import { useCancelToken } from '@/hooks/useCancelToken'
import { USER_SETTINGS_ACTION_TYPES } from '@/store/settings/actions'
import PasswordRequirements from '@/components/particles/forms/ForgetPasswordForm/PasswordRequirements.vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default defineComponent({
  name: 'PasswordSettingsView',
  emits: ['showPassword'],
  components: { PasswordRequirements },
  setup() {
    const passwordType = ref('password')
    const { t } = useI18n()
    const store = useStore()
    const getPersonalInfoCancelToken = useCancelToken()
    const { meta, handleSubmit, resetForm } = useForm({
      validationSchema: {
        oldPassword: 'required',
        newPassword: 'required',
        newPasswordAgain: 'required',
      },
    })
    const isError = computed(() => {
      if (store.state.userSettings.isError) {
        createToast({
          title: 'Erreur ' + store.state.userSettings.error.status,
          description: store.state.userSettings.error.message
        },
          {
            type: 'danger',
            position: 'bottom-right',
            showIcon: true,
          })
        store.dispatch(
          USER_SETTINGS_ACTION_TYPES.RESET_ERROR_STATE,
          getPersonalInfoCancelToken.value
        )
      }
      return store.state.userSettings.isError
    })
    const form = reactive({
      oldPassword: useField('oldPassword', '', {
        label: t('SETTINGS_MY_PASSWORD_OLD_PASSWORD_LABEL'),
      }),
      newPassword: useField('newPassword', '', {
        label: t('SETTINGS_MY_PASSWORD_NEW_PASSWORD_LABEL'),
      }),
      newPasswordAgain: useField('newPasswordAgain', '', {
        label: t('SETTINGS_MY_PASSWORD_NEW_PASSWORD_AGAIN_LABEL'),
      }),
    })

    const onSubmit = handleSubmit(async ({ oldPassword, newPassword }) => {
      try {
        const updatedPasswordInfo = {
          oldPassword,
          newPassword,
        };
        await store.dispatch(USER_SETTINGS_ACTION_TYPES.UPDATE_PASSWORD, updatedPasswordInfo)
        createToast({
          title: 'Modification effectuée',
        },
          {
            type: 'success',
            position: 'bottom-right',
            showIcon: true,
          })
        setTimeout(() => {
          resetForm()
        }, 1500)
      } catch (e) {
        // notification show
      }
    })

    const handleVisibility = (isShow: boolean) => {
      console.log("coucou",isShow)
      if (isShow) {
        passwordType.value = "text";
      } else {
       passwordType.value = "password";
      }
      console.log("passwordType",passwordType)
    }
    return {
      form,
      isPasswordValid: ref(false),
      onSubmit,
      handleVisibility,
      meta,
      passwordType,
      isValid: useIsFormValid(),
      isSubmitting: useIsSubmitting(),
      isError
    }
  },
})
