import { v4 as uuid } from 'uuid'
import useStore from '@/store'
import { onBeforeUnmount, ref, Ref } from 'vue'
import { API_ACTION_TYPES } from '@/store/api/actions'

export function useCancelToken(requestId = uuid()): Ref<string> {
  const store = useStore()
  const refRequestId = ref(requestId)
  onBeforeUnmount(() => {
    if (refRequestId.value)
      store.dispatch(API_ACTION_TYPES.CANCEL_REQUEST, refRequestId.value)
  })
  return refRequestId
}
